// ヘッダー背景の切り替え
function onScroll() {
  const header = document.querySelector('#js-header');
  const headerTop = document.querySelector('#js-header-top');
  const scroll = window.pageYOffset;

  if (header) {
    if (scroll > 200) {
      header.classList.add('is-scroll');
    } else {
      header.classList.remove('is-scroll');
    }
  } else if (headerTop) {
    if (window.matchMedia('(max-width: 768px)').matches) {
      if (scroll > 200) {
        headerTop.classList.add('is-scroll');
      } else {
        headerTop.classList.remove('is-scroll');
      }
    }
  }
}

window.addEventListener('scroll', onScroll);
