// フッター アコーディオンメニュー
function addMenuAcdEvent() {
  const acd = document.querySelectorAll('.js-acd');
  const acdTop = document.querySelectorAll('.js-acd-top');
  const openClass = 'is-open';

  if (acd || acdTop) {
    acd.forEach(function (value) {
      value.addEventListener('click', function () {
        if (window.matchMedia('(max-width: 768px)').matches) {
          value.classList.toggle(openClass);
        }
      });
    });
    acdTop.forEach(function (value) {
      value.addEventListener('click', function () {
        value.classList.toggle(openClass);
      });
    });
  }
}

window.addEventListener('DOMContentLoaded', addMenuAcdEvent);
