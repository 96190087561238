// ハンバーガーメニュー
function addMenuToggleEvent() {
  const header = document.querySelector('#js-header');
  const headerTop = document.querySelector('#js-header-top');
  const toggle = document.querySelector('#js-toggle');
  const openClass = 'is-open';

  if (header && toggle) {
    toggle.addEventListener('click', function () {
      header.classList.toggle(openClass);
      toggle.classList.toggle(openClass);
    });
  } else if (headerTop && toggle) {
    toggle.addEventListener('click', function () {
      headerTop.classList.toggle(openClass);
      toggle.classList.toggle(openClass);
    });
  }
}

window.addEventListener('DOMContentLoaded', addMenuToggleEvent);
